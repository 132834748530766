// extracted by mini-css-extract-plugin
export var Ready = "Expand-module--Ready--1ab9f";
export var bannerBtn = "Expand-module--bannerBtn--8b273";
export var btn_white2_border_Golang = "Expand-module--btn_white2_border_Golang--d5fb3";
export var card = "Expand-module--card--b8dd8";
export var cardWrapper = "Expand-module--cardWrapper--c1136";
export var con = "Expand-module--con--faea4";
export var description = "Expand-module--description--91cf8";
export var description2 = "Expand-module--description2--79307";
export var heading = "Expand-module--heading--b1953";
export var subserviceExpandGolang = "Expand-module--subserviceExpandGolang--ddbf8";
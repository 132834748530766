// extracted by mini-css-extract-plugin
export var attachment = "GolangDevelopersForm-module--attachment--11c78";
export var attachmentLabel = "GolangDevelopersForm-module--attachmentLabel--1fb4f";
export var errorTxt = "GolangDevelopersForm-module--errorTxt--cde52";
export var fieldColor = "GolangDevelopersForm-module--fieldColor--9a8b6";
export var formControl = "GolangDevelopersForm-module--form-control--47663";
export var formInner = "GolangDevelopersForm-module--formInner--2e99d";
export var hr = "GolangDevelopersForm-module--hr--ce693";
export var newlinks = "GolangDevelopersForm-module--newlinks--cd863";
export var query = "GolangDevelopersForm-module--query--d76ca";
export var salesFormCheckBoxSize = "GolangDevelopersForm-module--salesFormCheckBoxSize--7ab8b";
export var salesFormRowHeight = "GolangDevelopersForm-module--salesFormRowHeight--bd8f0";
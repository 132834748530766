import React from "react"
import * as styles from "./Skills.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Table from "react-bootstrap/Table"

const Skills = ({ strapiData }) => {
  const seniorLevelData = [
    {
      desc: "Highly experienced in designing and implementing distributed systems.",
    },
    {
      desc: "Expert knowledge of containerization technologies such as Docker and Kubernetes.",
    },
    {
      desc: "Skilled in cloud computing systems AWS and Azure.",
    },
    {
      desc: "Understanding of software architecture patterns such as Event-Driven Architecture and Domain- Driven Design.",
    },
    {
      desc: "Expert in Go programming language and its standard library.",
    },
    {
      desc: "Proficient in algorithms and data structures.",
    },
    {
      desc: "Expert knowledge in network programming and protocols.",
    },
    {
      desc: "Expert knowledge in network programming and protocols such as HTTP, TCP/IP and UDP.",
    },
    {
      desc: "Skilled in testing frameworks such as Go testing.",
    },
    {
      desc: "Proficient in working with microservices and APIs.",
    },
    {
      desc: "Expert in using Golang web frameworks such as Echo and Gin.",
    },
    {
      desc: "Skilled in database concepts and SQL also ORL frameworks; eg GORM.",
    },
    {
      desc: "Expert in message queuing technologies such as Kafka and RabbitMQ.",
    },
    {
      desc: "Proficient in distributed systems and concepts such as fault tolerance and load balancing.",
    },
  ]
  const middleLevelData = [
    {
      desc: "Proficient in Go programming language and its standard library.",
    },
    {
      desc: "Skilled in algorithms and data structures.",
    },
    {
      desc: "Experts in network programming and protocols such as HTTP, TCP/IP and UDP.",
    },
    {
      desc: "Seasoned in testing frameworks such as Go testing.",
    },
    {
      desc: "Proficient in working with microservices and APIs.",
    },
    {
      desc: "Working knowledge of containerization technologies such as Kubernetes and Docker.",
    },
    {
      desc: "Proficient in database concepts and SQL,ORL frameworks; e.g. GORM.",
    },
    {
      desc: "Familiarity with message queuing technologies such as Kafka and RabbitMQ.",
    },
    {
      desc: "Skilled in distributed systems and concepts such as fault tolerance and load balancing.",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
  ]
  const juniorLevelData = [
    {
      desc: "Basic understanding of Go programming languages syntax, control structures and data types.",
    },
    {
      desc: "Knowledge of basic data structures and algorithms.",
    },
    {
      desc: "Understanding of network protocols such as TCP/IP, HTTP and UDP.",
    },
    {
      desc: "Knowledge of database concepts and SQL.",
    },
    {
      desc: "Know how with testing frameworks such as Go testing.",
    },
    {
      desc: "Basic knowledge of version control such as Git.",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.mainTableSkills}>
        <Container>
          <div className={styles.SkillsHeading}>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            <p
              className={styles.SkillsDescription}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </div>
          <Row className={styles.tableRow}>
            <Table className={` mt-5 ${styles.skillsTable}`}>
              <thead className={styles.skillsTableHead}>
                <tr className={styles.tableRow}>
                  <td>SENIOR</td>
                  <td>MIDDLE</td>
                  <td>JUNIOR</td>
                </tr>
              </thead>
              <tbody
                style={{ borderRadius: "5px" }}
                className={styles.tableBody}
              >
                {seniorLevelData?.map((e, i) => (
                  <tr key={i} className={styles.tableBodyRow}>
                    <td>{e?.desc}</td>
                    <td
                      className={
                        middleLevelData[i]?.desc === "--" ? "text-center" : ""
                      }
                    >
                      {middleLevelData[i]?.desc}
                    </td>
                    <td
                      className={
                        juniorLevelData[i]?.desc === "--" ? "text-center" : ""
                      }
                    >
                      {juniorLevelData[i]?.desc}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Skills

// extracted by mini-css-extract-plugin
export var Golang = "NextGen-module--Golang--86aac";
export var GolangImg = "NextGen-module--GolangImg--c15a2";
export var GolangNextGen = "NextGen-module--GolangNextGen--61594";
export var bannerBtnGolangBook = "NextGen-module--bannerBtnGolangBook--54cff";
export var btn_white2_border_book_Goolang_dev = "NextGen-module--btn_white2_border_book_Goolang_dev--3122a";
export var card1 = "NextGen-module--card1--a5d27";
export var card2 = "NextGen-module--card2--35bce";
export var card3 = "NextGen-module--card3--359a2";
export var card4 = "NextGen-module--card4--f3cdc";
export var cardWrapper = "NextGen-module--cardWrapper--3ed47";
export var description = "NextGen-module--description--7a242";
export var heading = "NextGen-module--heading--f75e7";
export var imgs = "NextGen-module--imgs--79282";
export var imgs2 = "NextGen-module--imgs2--89591";
export var imgs3 = "NextGen-module--imgs3--e7012";
export var imgs4 = "NextGen-module--imgs4--e7a4c";
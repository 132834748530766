// extracted by mini-css-extract-plugin
export var GolangBanner = "Banner-module--GolangBanner--afcd1";
export var bannerHeading = "Banner-module--bannerHeading--8577d";
export var bannerSubtitle = "Banner-module--bannerSubtitle--17a25";
export var bannerSubtitle2 = "Banner-module--bannerSubtitle2--5bfdc";
export var bannerdesc = "Banner-module--bannerdesc--4bcda";
export var cards = "Banner-module--cards--a48ed";
export var form = "Banner-module--form--a5de4";
export var heading = "Banner-module--heading--6c2a5";
export var talent = "Banner-module--talent--55c18";
export var text = "Banner-module--text--37206";
export var tick = "Banner-module--tick--f164f";
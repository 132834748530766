// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--da38f";
export var clientDots2 = "Testimonials-module--clientDots2--83018";
export var content = "Testimonials-module--content--c7865";
export var dots3 = "Testimonials-module--dots3--6e0db";
export var hireArrow = "Testimonials-module--hireArrow--23bdc";
export var iconContainer = "Testimonials-module--iconContainer--8b63e";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--05e75";
export var imgBtn = "Testimonials-module--imgBtn--8a3f9";
export var person = "Testimonials-module--person--73f93";
export var personBg = "Testimonials-module--personBg--3ac54";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--2f2b1";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--7d657";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--995e4";
export var row = "Testimonials-module--row--a5771";
export var section = "Testimonials-module--section--cc444";
export var staffRec = "Testimonials-module--staffRec--7c6c3";
export var testName = "Testimonials-module--testName--27ae4";
export var testimonials = "Testimonials-module--testimonials--f0314";
export var testimonialsContent = "Testimonials-module--testimonialsContent--8160a";
export var vector2 = "Testimonials-module--vector2--8266f";
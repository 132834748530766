// extracted by mini-css-extract-plugin
export var btn = "Technical-module--btn--948c8";
export var cards = "Technical-module--cards--3168a";
export var description = "Technical-module--description--edfdf";
export var hireCyberGolangTechnical = "Technical-module--hireCyberGolangTechnical--e8b90";
export var hireImg = "Technical-module--hireImg--0b88b";
export var hireTechnical = "Technical-module--hireTechnical--92e2b";
export var subTitle = "Technical-module--subTitle--cdc83";
export var talent = "Technical-module--talent--1be5f";
export var teamBtn = "Technical-module--teamBtn--ad6ba";
export var text = "Technical-module--text--ebb90";
export var tick = "Technical-module--tick--4c8c1";
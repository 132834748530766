// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksMobile-module--cardBg--c6e1f";
export var cards = "TechStacksMobile-module--cards--aaf6f";
export var description = "TechStacksMobile-module--description--c1c1f";
export var heading = "TechStacksMobile-module--heading--7b443";
export var infaHeading = "TechStacksMobile-module--infaHeading--0dc5e";
export var nav = "TechStacksMobile-module--nav--f7ef4";
export var navItem = "TechStacksMobile-module--nav-item--88782";
export var navbarBlock = "TechStacksMobile-module--navbarBlock--2620f";
export var tabData = "TechStacksMobile-module--tabData--fce7a";
export var tech = "TechStacksMobile-module--tech--6776c";
export var techIconssCard = "TechStacksMobile-module--techIconssCard--bb1e5";
export var techInfrastructures = "TechStacksMobile-module--techInfrastructures--1a6f3";
export var techStackImg = "TechStacksMobile-module--techStackImg--a6d19";
export var techStackglobalIndusGolang = "TechStacksMobile-module--techStackglobalIndusGolang--527be";
export var techStacksCardsContent = "TechStacksMobile-module--techStacksCardsContent--be5ec";
export var techStacksIcon = "TechStacksMobile-module--techStacksIcon--444ea";
export var technologyStacksIcon = "TechStacksMobile-module--technologyStacksIcon--64869";
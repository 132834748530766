import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import * as styles from "./SkillsLevelMobile.module.scss"
import Table from "react-bootstrap/Table"
import "./SkillsLevelMobile.scss"
const SkillsLevelMobile = ({ strapiData }) => {
  const seniorLevelData = [
    {
      desc: "Highly experienced in designing and implementing distributed systems.",
    },
    {
      desc: "Expert knowledge of containerization technologies such as Docker and Kubernetes",
    },
    {
      desc: "Skilled in cloud computing systems AWS and Azure",
    },
    {
      desc: "Understanding of software architecture patterns such as Event-Driven Architecture and Domain- Driven Design",
    },
    {
      desc: "Expert in Go programming language and its standard library",
    },
    {
      desc: "Proficient in algorithms and data structures",
    },
    {
      desc: "Expert knowledge in network programming and protocols ",
    },
    {
      desc: "Expert knowledge in network programming and protocols such as HTTP, TCP/IP and UDP ",
    },
    {
      desc: "Skilled in testing frameworks such as Go testing. ",
    },
    {
      desc: "Proficient in working with microservices and APIs ",
    },
    {
      desc: "Expert in using Golang web frameworks such as Echo and Gin",
    },
    {
      desc: "Skilled in database concepts and SQL also ORL frameworks; eg GORM ",
    },
    {
      desc: "Expert in message queuing technologies such as Kafka and RabbitMQ.  ",
    },
    {
      desc: "Proficient in distributed systems and concepts such as fault tolerance and load balancing",
    },
  ]
  const middleLevelData = [
    {
      desc: "Proficient in Go programming language and its standard library",
    },
    {
      desc: "Skilled in algorithms and data structures",
    },
    {
      desc: "Experts in network programming and protocols such as HTTP, TCP/IP and UDP",
    },
    {
      desc: "Seasoned in testing frameworks such as Go testing.",
    },
    {
      desc: "Proficient in working with microservices and APIs",
    },
    {
      desc: "Working knowledge of containerization technologies such as Kubernetes and Docker",
    },
    {
      desc: "Proficient in database concepts and SQL,ORL frameworks; e.g. GORM ",
    },
    {
      desc: "Familiarity with message queuing technologies such as Kafka and RabbitMQ.   ",
    },
    {
      desc: "Skilled in distributed systems and concepts such as fault tolerance and load balancing.",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
  ]
  const juniorLevelData = [
    {
      desc: "Basic understanding of Go programming languages syntax, control structures and data types.",
    },
    {
      desc: "Knowledge of basic data structures and algorithms",
    },
    {
      desc: "Understanding of network protocols such as TCP/IP, HTTP and UDP.",
    },
    {
      desc: "Knowledge of database concepts and SQL",
    },
    {
      desc: "Know how with testing frameworks such as Go testing ",
    },
    {
      desc: "Basic knowledge of version control such as Git",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
    {
      desc: "--",
    },
  ]
  const levelsData = [
    {
      level: "SENIOR",
      data: seniorLevelData,
    },
    {
      level: "MIDDLE",
      data: middleLevelData,
    },
    {
      level: "JUNIOR",
      data: juniorLevelData,
    },
  ]
  const [activeAccordion, setActiveAccordion] = useState(
    `${levelsData[0]?.level}`
  )
  return (
    <div className={styles.mainTableSkillsMobile}>
      <Container>
        <div className={styles.SkillsHeading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            className={styles.SkillsDescription}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Accordion defaultActiveKey={levelsData[0]?.level}>
          {levelsData.map((levelData, index) => (
            <Card className="" key={index}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={levelData?.level}
                className={`techAccord ${
                  levelData?.level === activeAccordion
                    ? "active-accordion"
                    : "second-accordion"
                }`}
                onClick={() => setActiveAccordion(levelData?.level)}
                style={
                  levelData?.level !== activeAccordion
                    ? { marginTop: "24px" }
                    : {}
                }
              >
                {levelData?.level}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={levelData?.level}>
                <Card.Body className={`cardBodyGolangTable`}>
                  <Table
                    className={` mobileTable  ${styles.skillsTableMobile}`}
                  >
                    <tbody
                      style={{ borderRadius: "5px" }}
                      className={` mobileTableBody ${styles.tableMobileBody}`}
                    >
                      {levelData?.data?.map((e, i) => (
                        <tr
                          key={i}
                          className={` tableMobileRow ${styles.tableBodyRow}`}
                        >
                          <td
                            className={`tableDataMobile ${
                              styles.tableDataMobile
                            } ${e?.desc === "--" ? "text-center" : ""}`}
                          >
                            {e?.desc}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Container>
    </div>
  )
}

export default SkillsLevelMobile

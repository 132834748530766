import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./TechStacks.scss"
import * as styles from "./TechStacks.module.scss"

const data = [
  {
    title: "Framework",
    tech: [
      {
        name: "Gin",
        icon: "https://invozone-backend.s3.amazonaws.com/gin_cae7b9dba8.svg",
      },
      {
        name: "Echo",
        icon: "https://invozone-backend.s3.amazonaws.com/echo_92479ec73f.svg",
      },
      {
        name: "Beego",
        icon: "https://invozone-backend.s3.amazonaws.com/beego_0434c6afa8.svg",
      },
      {
        name: "Revel",
        icon: "https://invozone-backend.s3.amazonaws.com/revel_736e186866.svg",
      },
      {
        name: "Buffalo",
        icon: "https://invozone-backend.s3.amazonaws.com/buffalo_ca55f93119.svg",
      },
      {
        name: "Goa",
        icon: "https://invozone-backend.s3.amazonaws.com/goa_469b2575a3.svg",
      },
      {
        name: "Fiber",
        icon: "https://invozone-backend.s3.amazonaws.com/fiber_c3bb97f5c0.svg",
      },
      {
        name: "Chi",
        icon: "https://invozone-backend.s3.amazonaws.com/chi_d209b0abe6.svg",
      },
      {
        name: "Google Protobuf",
        icon: "https://invozone-backend.s3.amazonaws.com/image_343_28c150fef8.svg",
      },
    ],
  },
  {
    title: "Package",
    tech: [
      {
        name: "Chi",
        icon: "https://invozone-backend.s3.amazonaws.com/chi_d209b0abe6.svg",
      },
      {
        name: "Stringer",
        icon: "https://invozone-backend.s3.amazonaws.com/stringer_7dc6f8105a.svg",
      },
      {
        name: "Gorilla Mux",
        icon: "https://invozone-backend.s3.amazonaws.com/gorilla_mux_a0aa477122.svg",
      },
      {
        name: "GORM",
        icon: "https://invozone-backend.s3.amazonaws.com/gorm_7ba313a59b.svg",
      },
    ],
  },
  {
    title: "Database",
    tech: [
      {
        name: "MY SQl",
        icon: "https://invozone-backend.s3.amazonaws.com/my_sql_a6d6bc28f4.svg",
      },
      {
        name: "Mongo DB",
        icon: "https://invozone-backend.s3.amazonaws.com/mongodb_985fdf4845.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.amazonaws.com/redis_22c78d025d.svg",
      },
      {
        name: "Postgres",
        icon: "https://invozone-backend.s3.amazonaws.com/pg_admin_dd641975d8.svg",
      },
      {
        name: "ElasticSearch",
        icon: "https://invozone-backend.s3.amazonaws.com/elasticsearch_bf7cbeea93.svg",
      },
    ],
  },
  {
    title: "Cloud Services",
    tech: [
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.amazonaws.com/docker_efecb7c119.svg",
      },
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.amazonaws.com/aws_0dbbb34e02.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.amazonaws.com/azure_bf4f516357.svg",
      },
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_1e96a7f540.svg",
      },
      {
        name: "Digital Ocean",
        icon: "https://invozone-backend.s3.amazonaws.com/digital_ocean_8de6b803d3.svg",
      },
    ],
  },
  {
    title: "Tools",
    tech: [
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.amazonaws.com/postman_1b9276abd0.svg",
      },
      {
        name: "PG Admin",
        icon: "https://invozone-backend.s3.amazonaws.com/pg_admin_666b5af0aa.svg",
      },
      {
        name: "Git",
        icon: "https://invozone-backend.s3.amazonaws.com/git_55cbb7834c.svg",
      },
      {
        name: "gitlab",
        icon: "https://invozone-backend.s3.amazonaws.com/gitlab_e9cc8b3043.svg",
      },
    ],
  },
  {
    title: "Communication",
    tech: [
      {
        name: "Rest APIs",
        icon: "https://invozone-backend.s3.amazonaws.com/rest_apis_ec5e76b0a3.svg",
      },
      {
        name: "gRPC",
        icon: "https://invozone-backend.s3.amazonaws.com/grpc_3e565c754e.svg",
      },
      {
        name: "Web Sockets",
        icon: "https://invozone-backend.s3.amazonaws.com/sockets_2_34f860a115.svg",
      },
      {
        name: "GraphQl",
        icon: "https://invozone-backend.s3.amazonaws.com/graph_Ql_2_51bcd173ff.svg",
      },
      {
        name: "Apache",
        icon: "https://invozone-backend.s3.amazonaws.com/apache_109a896795.svg",
      },
      {
        name: "Kafka",
        icon: "https://invozone-backend.s3.amazonaws.com/kafka_f99f931d9b.svg",
      },
      {
        name: "RabbitMQ",
        icon: "https://invozone-backend.s3.amazonaws.com/rabbit_3e6d3a9f46.svg",
      },
    ],
  },
]

const TechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="GolangTabStacks">
      <div className={styles.globalIndusGolangStacks}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`GolangTabStacks`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default TechStacks

import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-Golang/Banner"
import GolangStatics from "../components/hire-Golang/GolangStatics"
import BestDevelopers from "../components/hire-Golang/BestDevelopers"
import WhyChoose from "../components/hire-Golang/WhyChoose"
import OurDevelopers from "../components/hire-pages-compoents/HireDevelopers"
import Process from "../components/hire-Golang/Process"
import Technical from "../components/hire-Golang/Technical"
import Expand from "../components/hire-Golang/Expand"
import Need from "../components/hire-Golang/Need"
import Skills from "../components/hire-Golang/Skills"
import NextGen from "../components/hire-Golang/NextGen"
import Elevate from "../components/hire-Golang/Elevate"
import TechStacks from "../components/hire-Golang/TechStacks"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Testimonials from "../components/hire-Golang/Testimonials"
import Testimonials2 from "../components/hire-Golang/Testimonials2"
import Faqs from "../components/common/Faqs2"
import SkillsLevelMobile from "../components/hire-Golang/SkillsLevelMobile"
import TechStacksMobile from "../components/hire-Golang/TechStacksMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const hireGolang = ({ data }) => {
  //console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const bestDeveloper = data?.strapiPage?.sections[1]
  const whyGolang = data?.strapiPage?.sections[2]
  const developers = data?.strapiPage?.sections[3]
  const process = data?.strapiPage?.sections[4]
  const technical = data?.strapiPage?.sections[5]
  const skills = data?.strapiPage?.sections[6]
  const expand = data?.strapiPage?.sections[7]
  const need = data?.strapiPage?.sections[8]
  const nextGen = data?.strapiPage.sections[9]
  const elevate = data?.strapiPage.sections[10]
  const tech = data?.strapiPage.sections[11]
  const portfolio = data?.strapiPage.sections[12]
  const faqs = data?.strapiPage.sections[13]
  const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "Safdar Abbas",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/safdar_dev_9eb469e18b.png",
      expert: "Microservices Expert",
      experience: "8 years of experience",
      desc: "He develops scalable applications, with strong knowledge of concurrent programming, microservices architecture and performs code optimization for efficiency.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_df89fd802b.svg",
      reviews: "<b>5.0</b> Reviews",
      skills: [
        "Go",
        "Fiber",
        "Gin",
        "Redis",
        "Goroutines",
        "Eco",
        "Kubernetes",
        "Docker",
        "MySQL",
        "MongoDB",
        "Gorilla Mux",
        "Elastic",
        "Rabbit MQ",
        "Kafka",
        "GoogleSSO",
        "Apache",
      ],
    },
    {
      name: "Hamza Sajid",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/hamza_dev_7abcd63f18.png",
      expert: "Microservices Expert",
      experience: "5 years of experience",
      desc: "Hamza possesses expertise in developing robust and efficient software solutions. So hire him for your team to get high-performing and efficient applications.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_df89fd802b.svg",
      reviews: "<b>5.0</b> Stars Reviews",
      skills: [
        "Go",
        " Gin",
        "JavaScript",
        "Fiber",
        "Kubernetes",
        "Docker",
        "Postgres",
        "Gorilla Mux",
        "MySQL",
        "MongoDB",
        "Elastic",
        "Rabbit MQ",
        "Kafka",
        "GoogleSSO",
        "Apache",
      ],
    },
    {
      name: "Nadeem Aslam",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/nadeem_dev_f1fd49da8c.png",
      experience: "8 Years Of Experience",
      desc: "Nadeem can build scalable solutions with ability to design & maintain complex systems with track of high performance and delivery of production ready software.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_df89fd802b.svg",
      reviews: "<b>5.0</b> Stars Reviews",
      skills: [
        "Go",
        "Docker",
        "Beego",
        "Fiber",
        "Goroutines",
        "MongoDB",
        "Gorilla Mux",
        "MySQL",
        "Elastic",
        "Rabbit MQ",
        "Kafka",
        "GoogleSSO",
        "Apache",
      ],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} pageName="Hire Golang Developer" />
      <GolangStatics />
      <BestDevelopers strapiData={bestDeveloper} />
      <WhyChoose strapiData={whyGolang} />
      <OurDevelopers strapiData={developers} devSDetails={devSDetails} />
      <Process strapiData={process} />
      <Technical strapiData={technical} />
      {!isMobile && !isTablet ? (
        <Skills strapiData={skills} />
      ) : (
        <SkillsLevelMobile strapiData={skills} />
      )}
      <Expand strapiData={expand} />
      <Need strapiData={need} />
      <NextGen strapiData={nextGen} />
      <Elevate strapiData={elevate} />
      {!isMobile && !isTablet ? (
        <TechStacks strapiData={tech} />
      ) : (
        <TechStacksMobile strapiData={tech} />
      )}
      <WorkPortfolio strapiData={portfolio} />
      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={"Your Success Is Our Aim"}
          testiSubtitle={
            "For us, you're not just another client; you are a partner, and we strive to help you stand out in the market."
          }
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={"Your Success Is Our Aim"}
          testiSubtitle={
            "For us, you're not just another client; you are a partner, and we strive to help you stand out in the market."
          }
        />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "hire-Golang" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default hireGolang

import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import GolangForm from "./GolangDeveloperForm"
import bannerTick from "../../images/hireGolang/bannerIcon.svg"

const Banner = ({ strapiData, pageName }) => {
  return (
    <div id="golangBanner" className={styles.GolangBanner}>
      <Container>
        <Row className="align-items-center">
          <Col xl={6} md={12} lg={6}>
            <div className={styles.heading}>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.bannerSubtitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <p className={styles.bannerdesc}>
                Hire Golang developers to build scalable, customized, and
                high-performing solutions. Our Go developers are experts and
                their skill set include:
              </p>
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img
                      className={styles.tick}
                      src={bannerTick}
                      alt={item?.title}
                      decoding="async"
                      loading="lazy"
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
            <p
              className={styles.bannerSubtitle2}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle2,
              }}
            />
          </Col>
          <Col xl={6} md={12} lg={6} className={styles.form}>
            <GolangForm pageName={pageName} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner

// extracted by mini-css-extract-plugin
export var SkillsDescription = "SkillsLevelMobile-module--SkillsDescription--6487e";
export var SkillsHeading = "SkillsLevelMobile-module--SkillsHeading--10ad5";
export var description = "SkillsLevelMobile-module--description--ea8d4";
export var globalTechMobileLevel = "SkillsLevelMobile-module--globalTechMobileLevel--038f1";
export var heading = "SkillsLevelMobile-module--heading--e64a0";
export var mainTableSkillsMobile = "SkillsLevelMobile-module--mainTableSkillsMobile--b5a7f";
export var skillsTableMobile = "SkillsLevelMobile-module--skillsTableMobile--eb0ec";
export var tableBodyRow = "SkillsLevelMobile-module--tableBodyRow--32d9b";
export var tableDataMobile = "SkillsLevelMobile-module--tableDataMobile--7d891";
export var tableMobileBody = "SkillsLevelMobile-module--tableMobileBody--321b8";
export var tableRow = "SkillsLevelMobile-module--tableRow--8dd52";
export var techMobileGolang = "SkillsLevelMobile-module--techMobileGolang--92a2c";
export var techMobileSkillsLevel = "SkillsLevelMobile-module--techMobileSkillsLevel--49b97";
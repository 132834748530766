import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./WhyChoose.module.scss"

const WhyChoose = ({ strapiData }) => {
  return (
    <div className={styles.WhyChooseGolang}>
      <Container>
        <div className={styles.heading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col
              key={i}
              lg={3}
              md={6}
              sm={12}
              xs={12}
              className=" justify-content-center  d-flex"
            >
              <div className={styles.cards}>
                <lottie-player
                  autoplay
                  loop
                  src={e?.image1[0]?.localFile?.publicURL}
                  style={{ height: "60px", width: "60px", margin: "auto" }}
                />
                <h3>{e?.title}</h3>
              </div>
            </Col>
          ))}
        </Row>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className={styles.getBtn}>
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default WhyChoose

import { Link } from "gatsby"
import React from "react"
import * as styles from "./NextGen.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import python from "../../images/hireGolang/python.svg"
import golang from "../../images/hireGolang/golang.svg"
import js from "../../images/hireGolang/js.svg"
import node from "../../images/hireGolang/nodejs.svg"
import ror from "../../images/hireGolang/ror.svg"
import react from "../../images/hireGolang/reactJs.svg"
import angular from "../../images/hireGolang/angular.svg"
import vue from "../../images/hireGolang/vue.svg"
const NextGen = ({ strapiData }) => {
  const nextGenData = [
    {
      stcak1: python,
      stack2: golang,
      title: "Python+ Go",
      desc: "Python & Go can be used together in various scenarios, with Python for scripting and high-level tasks and Go providing performance-critical components.",
    },
    {
      stcak1: js,
      stack2: node,
      stack3: golang,
      title: "JavaScript/Node.js + Go",
      desc: "JavaScript or Node.js with Go can be useful for building full-stack web applications, here Go serves are used as the backend & JavaScript/Node.js handles front end.",
    },
    {
      stcak1: ror,
      stack2: golang,
      title: "Rust+ Go",
      desc: "Go and Rust can be complementary, with Rust being used for system-level programming and Go for building applications logic and servers.",
    },
    {
      stcak1: react,
      stack2: angular,
      stack3: vue,
      stack4: golang,
      title: "React, Angular, Vue.js + Go",
      desc: "The frontend frameworks like React, Angular or Vue.js can be used with Go to create dynamic web applications.",
    },
  ]
  return (
    <div className={styles.GolangNextGen}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 justify-content-center ${styles.cardWrapper}`}>
          {nextGenData.map((e, i) => (
            <Col xl={6} md={6} xs={12} key={i} className={styles.Golang}>
              <div
                className={
                  i === 0
                    ? styles.card1
                    : i === 1
                    ? styles.card2
                    : i === 2
                    ? styles.card3
                    : i === 3
                    ? styles.card4
                    : ""
                }
              >
                {i === 0 ? (
                  <div className={styles.imgs}>
                    <img
                      className={styles.GolangImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.GolangImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 1 ? (
                  <div className={styles.imgs2}>
                    <img
                      className={styles.GolangImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.GolangImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.GolangImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 2 ? (
                  <div className={styles.imgs3}>
                    <img
                      className={styles.GolangImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.GolangImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 3 ? (
                  <div className={styles.imgs4}>
                    <img
                      className={styles.GolangImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.GolangImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.GolangImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.GolangImg}
                      src={e?.stack4}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : (
                  ""
                )}
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.desc,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className={`${styles.bannerBtnGolangBook}`}>
          {strapiData?.buttons[0] && (
            <Link
              to="#golangBanner"
              className={styles.btn_white2_border_book_Goolang_dev}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default NextGen

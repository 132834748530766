import React from "react"
import * as styles from "./GolangStatics.module.scss"
import g2 from "../../images/hire-saas/g2logo.svg"
import clutch from "../../images/hireMernStack/clutch.svg"
import goodFirm from "../../images/hireMernStack/good-firm.svg"
import stars from "../../images/hireMernStack/topStars.svg"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const GolangStatics = () => {
  const data = [
    {
      img: g2,
      number: "5",
      star: stars,
      desc: "G2",
    },
    {
      img: clutch,
      number: "5",
      star: stars,
      desc: "Clutch",
    },
    {
      img: goodFirm,
      number: "5",
      star: stars,
      desc: "Goodfirms",
    },
  ]
  return (
    <div className={styles.Golangstatics}>
      <Container className={styles.staticsContainer}>
        <Row className={styles.Wrapper}>
          {data &&
            data?.map((e, i) => (
              <Col
                lg={4}
                md={12}
                sm={12}
                xs={12}
                key={i}
                className={
                  i === 0 ? styles.card1 : i === 1 ? styles.card2 : styles.card
                }
              >
                <Row>
                  <Col lg={3} md={2} xs={2} sm={2}>
                    <div className={styles.cardImg}>
                      <img
                        src={e?.img}
                        alt="stack"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </Col>
                  <Col
                    lg={9}
                    md={10}
                    sm={10}
                    xs={10}
                    className={styles.starDiv}
                  >
                    <div className="d-flex align-items-center">
                      <div>{e?.number}</div>
                      <div className={styles.starImage}>
                        <img
                          src={e?.star}
                          alt="star"
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div>
                      <p>{e?.desc}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default GolangStatics

// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--194a1";
export var circleTes = "Testimonials2-module--circleTes--6e35c";
export var content = "Testimonials2-module--content--9a953";
export var des = "Testimonials2-module--des--eab8c";
export var iconContainer = "Testimonials2-module--iconContainer--f2c61";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--0540c";
export var personBg = "Testimonials2-module--personBg--a0deb";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--fa7b2";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--409da";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--782e0";
export var row = "Testimonials2-module--row--7ccae";
export var section = "Testimonials2-module--section--10aff";
export var tesBtn = "Testimonials2-module--tesBtn--83c43";
export var tesDots = "Testimonials2-module--tesDots--5ed2f";
export var tesRec = "Testimonials2-module--tesRec--34bc6";
export var testimonials = "Testimonials2-module--testimonials--0cd3c";
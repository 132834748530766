// extracted by mini-css-extract-plugin
export var cardBg = "TechStacks-module--cardBg--77dd7";
export var cards = "TechStacks-module--cards--ee201";
export var cardsContent = "TechStacks-module--cardsContent--21f5f";
export var description = "TechStacks-module--description--8e715";
export var globalIndusGolangStacks = "TechStacks-module--globalIndusGolangStacks--f424b";
export var heading = "TechStacks-module--heading--a02b4";
export var iconssCard = "TechStacks-module--iconssCard--e7978";
export var nav = "TechStacks-module--nav--5f2e1";
export var navItem = "TechStacks-module--nav-item--2d579";
export var navbarBlock = "TechStacks-module--navbarBlock--6f1c1";
export var tabData = "TechStacks-module--tabData--edcea";
export var tech = "TechStacks-module--tech--29207";
export var techIcon = "TechStacks-module--techIcon--71294";
export var techImg = "TechStacks-module--techImg--2873b";
export var technologyIcon = "TechStacks-module--technologyIcon--e0757";
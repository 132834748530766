import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import * as styles from "./Expand.module.scss"

const Expand = ({ strapiData }) => {
  return (
    <div className={`${styles.subserviceExpandGolang}`}>
      <Container className={styles.con}>
        <div className={styles.Ready}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to="#golangBanner"
                className={styles.btn_white2_border_Golang}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Expand
